import React, { useCallback, useMemo } from "react";
import { Dropdown } from "@commonsku/styles";
import { useReportContext } from "../report_context";
import { useDispatch } from "react-redux";
import {
  createLoadReportPopup,
  createSaveReportPopup,
} from "../../../actions/popup";
import { useHasCapabilities } from "../../../hooks";

interface ActionMenuOption {
  onClick: () => void;
  content: string | React.ReactNode;
  active: boolean;
  show: boolean;
}

const DEFAULT_EXPORT_URL = "export_report.php";

const ActionMenu: React.FC = () => {
  const {
    reportConfig: { type, exportUrl = DEFAULT_EXPORT_URL },
    getRawQuery,
  } = useReportContext();
  const hasExportReport = useHasCapabilities("EXPORT-REPORT");
  const hasSaveLoadReport = useHasCapabilities(["HAS-SAVE-LOAD-REPORTS"]);
  const dispatch = useDispatch();

  const exportReport = useCallback(
    (csku_report = false) => {
      const query = getRawQuery();
      query["report_type"] = type.toLowerCase();
      query["csku_report"] = `${csku_report}`;
      window.open(
        `${window.location.origin}/${exportUrl}?${new URLSearchParams(
          query,
        ).toString()}`,
      );
    },
    [exportUrl, getRawQuery, type],
  );

  const options = useMemo<ActionMenuOption[]>(
    () => [
      {
        onClick: () => dispatch(createSaveReportPopup()),
        content: "Save Report",
        active: true,
        show: hasSaveLoadReport,
      },
      {
        onClick: () => dispatch(createLoadReportPopup(type)),
        content: "Load Report",
        active: true,
        show: hasSaveLoadReport,
      },
      {
        onClick: () => exportReport(),
        content: "Export Report",
        active: hasExportReport,
        show: true,
      },
    ],
    [dispatch, exportReport, hasExportReport, type, hasSaveLoadReport],
  );

  return <Dropdown items={options.filter((option) => option.show)} />;
};

export default ActionMenu;
