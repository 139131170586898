import styled from "styled-components";
import { Button, colors, IconButton } from "@commonsku/styles";
import { FILTER_STYLE_CONSTANTS } from "./config/ReportFilterConfig";
import { STYLE_CONSTANTS } from "../config";

const {
  SINGLE_BTN_AREA_WIDTH,
  TWO_BTNS_AREA_WIDTH,
  MORE_FILTERS_BTN_WIDTH,
  FILTER_ITEMS_GAP,
  BTNS_GAP,
} = FILTER_STYLE_CONSTANTS;

const { SIDE_BAR_WIDTH } = STYLE_CONSTANTS;

export const FiltersContainer = styled.div<{ height: number }>`
  width: 100% !important;
  height: ${({ height }) => height}px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px !important;
  margin-bottom: 20px !important;
`;

export const FiltersItems = styled.div`
  display: flex;
  width: calc(100% - 14px - 115px - 8px);
`;

export const BtnsContainer = styled.div<{ single: boolean }>`
  flex-basis: ${({ single }) =>
    single ? SINGLE_BTN_AREA_WIDTH : TWO_BTNS_AREA_WIDTH}px !important;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  width: ${({ single }) =>
    single ? SINGLE_BTN_AREA_WIDTH : TWO_BTNS_AREA_WIDTH}px !important;
  height: 48px;
  align-self: end;
`;

export const BtnText = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  color: ${colors["primary1"]};
`;

export const GetReportButton = styled(Button)`
  padding: 0 !important;
  height: 48px;
  width: 121px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const MoreFiltersBtnContainer = styled.div<{ active: boolean }>`
  height: ${({ active }) => (active ? "72px" : "48px")};
  width: ${MORE_FILTERS_BTN_WIDTH}px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: ${({ active }) => (active ? "4px 4px 0 4px" : "4px")};
  border: 3px solid rgb(0, 160, 182);
  border-bottom-color: ${({ active }) =>
    active ? "transparent" : "rgb(0, 160, 182)"};
`;

export const MoreFiltersButton = styled(IconButton)`
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  border: none !important;
  position: absolute;
  left: 0;
  top: 0;
`;

export const MoreFiltersContainer = styled.div<{ active: boolean }>`
  // 16px is the padding of the whole report container
  width: calc(
    100vw - ${BTNS_GAP}px - ${SINGLE_BTN_AREA_WIDTH}px - 16px -
      ${SIDE_BAR_WIDTH}px
  );
  position: absolute;
  top: 45px;
  right: -11px;
  border-radius: 5px 0 5px 5px;
  border-width: 3px;
  border-color: rgb(0, 160, 182);
  border-style: solid;
  padding: 16px 16px 32px 16px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: ${FILTER_ITEMS_GAP}px;
  row-gap: 20px;
  margin: 20px 8px 0 8px !important;
  z-index: 3;
  background-color: ${colors.white};
`;

export const MoreFiltersDummySpace = styled.div`
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 26px;
  z-index: 4;
  background-color: #fff;
`;
